@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap");

@layer base {
  html {
    font-family: "Public Sans", system-ui, sans-serif;
  }
}

:root {
  --color-neutral-soft: #ffffff;
  --color-neutral-strong: #0a0b0c;

  --color-status-success-base: #315c16;
  --color-status-success-bg: #edf3e9;
  --color-status-info-base: #ebf0ff;
  --color-status-info-text: #2447b3;

  --color-primary-base: #d91c03;
  --color-neutral-base-2: #f0f1f2;
  --color-neutral-base-3: #939aa2;
  --color-neutral-base-4: #1e4578;
  --color-neutral-base-5: #474d55;

  --color-status-danger-base: #e71d36;
  --color-status-danger-soft: #fde8eb;
  --color-status-danger-strong: #a21426;
  --color-status-danger-strong: #a21426;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}
